import window from 'window-or-global';
import cookie from 'react-cookie';
import axios from 'axios';
import React from 'react';
import get from 'lodash/get';
import { isMobile } from 'client-utils/utilities-page';
import itemsInShoppingBagSVG from 'assets/images/itemsinbag.svg';
import getLinkDomain from 'client/utilities/getlinkDomain';
import Notification from './Account/Notification';

export const ENTER_KEYCODE = 13;

const hashCodes = {
  0: 'b',
  1: 'm',
  2: 'k',
  3: 'y',
  4: '2',
  5: 'h',
  6: 'c',
  7: '3',
  8: '5',
  9: 'n',
  '-': 'j',
  '#': 'V',
  $: '8',
  '.': 'a',
  '@': '6',
  _: 'z',
  '~': 'o',
  '+': 'J',
  '<': '9',
  a: 'f',
  b: 'P',
  c: 'l',
  d: 'N',
  e: '0',
  f: 'x',
  g: 'R',
  h: 'Q',
  i: '1',
  j: 'p',
  k: 't',
  l: 'w',
  m: '7',
  n: 'S',
  o: 'g',
  p: 'i',
  q: 'e',
  r: '4',
  s: 'd',
  t: 'E',
  u: 'u',
  v: 'B',
  w: 'r',
  x: 's',
  y: 'v',
  z: 'q',
};

export const removeUtagData = (data, property) => {
  const utagProperty = property || (window.utag_data_dt ? 'utag_data_dt' : 'utag_data');
  delete window[utagProperty][data];

  return window[utagProperty];
};

export const updateUtagData = (data, property) => {
  const utagProperty = property || (window.utag_data_dt ? 'utag_data_dt' : 'utag_data');
  window[utagProperty] = {
    ...window[utagProperty],
    ...data,
  };

  return window[utagProperty];
};

export const sendUtagLinkData = (data) => {
  const utagData = updateUtagData(data);

  window.utag?.link?.(utagData);
};

export const updateUtagView = (data, property) => {
  const utagProperty = property || (window?.utag_data_dt ? 'utag_data_dt' : 'utag_data');

  window?.utag?.view?.({ ...window[utagProperty], ...data });
};

export const sendUtagLinkWithoutUpdatingUtagData = (data, property) => {
  const utagProperty = property || (window?.utag_data_dt ? 'utag_data_dt' : 'utag_data');

  window?.utag?.link?.({ ...window[utagProperty], ...data });
};

export const filterLocalCognito = (suffix) => {
  if (typeof localStorage !== 'undefined') {
    const prefix = 'CognitoIdentityServiceProvider';
    return Object.keys(localStorage).filter(
      (item) => item.startsWith(prefix) && item.endsWith(suffix)
    )[0];
  }
  return null;
};

export const filterGuestUser = (suffix) => {
  if (typeof localStorage !== 'undefined') {
    const prefix = 'guestUser';
    return Object.keys(localStorage).filter(
      (item) => item.startsWith(prefix) && item.endsWith(suffix)
    )[0];
  }
  return null;
};

export const filterUserAttributes = (userData, attr) => {
  const { Value = '' } = JSON.parse(
    localStorage[userData]
  ).UserAttributes.filter((key) => key.Name === attr)[0];
  return Value;
};

export const getSaData = (isV2ToggleOn) => {
  // wil be removed when api is migratedto new lambda host
  const getLinkDomain = () => {
    if (typeof window !== 'undefined') {
      if (
        window.location.hostname.includes('localhost')
        || window.location.hostname.includes('devint')
      ) {
        return 'https://dev-int-amber.api-nm.io';
      }
      if (window.location.hostname.includes('prep') || window.location.hostname.includes('stage')) {
        return 'https://prep-beige.api-nm.io';
      }
      return 'https://prod-beige.api-nm.io';
    }
    return 'https://prod-beige.api-nm.io';
  };
  const idToken = filterLocalCognito('idToken');
  const userData = filterLocalCognito('userData');

  if (idToken && userData) {
    const token = localStorage[idToken];

    const webProfileId = filterUserAttributes(userData, 'preferred_username');
    const url = isV2ToggleOn ? 'v2' : 'v1';
    return axios.get(
      `${getLinkDomain()}/oce-csa-relationships/${url}/customers/${webProfileId}/salesassociates`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  return new Promise((resolve) => {
    resolve({ data: [] });
  });
};

export const sendEmail = (email, message, saEmail, saName, saPin) => {
  const idToken = filterLocalCognito('idToken');
  const userData = filterLocalCognito('userData');

  if (idToken && userData) {
    const token = localStorage[idToken];
    const webProfileId = filterUserAttributes(userData, 'preferred_username');
    const firstName = filterUserAttributes(userData, 'given_name');
    const lastName = filterUserAttributes(userData, 'family_name');

    const data = {
      customer: { firstName, lastName, email },
      associate: {
        email: saEmail,
        name: saName,
        pin: saPin,
      },
      message,
    };

    return axios.post(`/dt/api/mailSa/${webProfileId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return new Promise((resolve) => {
    resolve({ data: [] });
  });
};

export const sendShoppingBag = (email, message, saEmail, saName, saPin) => {
  const idToken = filterLocalCognito('idToken');
  const userData = filterLocalCognito('userData');

  if (idToken && userData) {
    const token = localStorage[idToken];
    const webProfileId = filterUserAttributes(userData, 'preferred_username');
    const firstName = filterUserAttributes(userData, 'given_name');
    const lastName = filterUserAttributes(userData, 'family_name');

    const data = {
      customer: { firstName, lastName, email },
      associate: {
        email: saEmail,
        name: saName,
        pin: saPin,
      },
      message,
    };

    return axios.post(`/dt/api/shoppingBag/${webProfileId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return new Promise((resolve) => {
    resolve({ data: [] });
  });
};

export const getStoreJson = (number) => {
  const jsonUrl = `https://stores.neimanmarcus.com/info/${number}.json`;
  return axios.get(jsonUrl);
};

export const goToApptUrl = (id) => {
  if (typeof sessionStorage !== 'undefined') {
    const cachedStore = sessionStorage.getItem(id);
    if (cachedStore) {
      const data = JSON.parse(cachedStore);
      const { link } = data.storeServices.onlineAptBooking;
      link && window.open(link, '_blank');
    } else {
      getStoreJson(id).then(({ data }) => {
        sessionStorage.setItem(id, JSON.stringify(data));
        const { link } = data.storeServices.onlineAptBooking;
        link && window.open(link, '_blank');
      });
    }
  }
};

export const goToAltUrl = async (id) => {
  const defaultUrl = 'https://stores.neimanmarcus.com/stores/locations';
  if (typeof sessionStorage !== 'undefined') {
    const cachedStore = await sessionStorage.getItem(id);
    if (cachedStore) {
      const data = JSON.parse(cachedStore);
      const link = data.storeServices?.curbside?.links?.altLink1?.url || defaultUrl;
      link && window.open(link, '_blank');
    } else {
      getStoreJson(id).then(({ data }) => {
        sessionStorage.setItem(id, JSON.stringify(data));
        const link = data.storeServices?.curbside?.links?.altLink1?.url || defaultUrl;
        link && window.open(link, '_blank');
      });
    }
  }
};

export const setSelectedZip = (zip) => {
  cookie.save('dt_selected_zipcode', JSON.stringify(zip), { path: '/' });
};

export const getStoreList = (location) => {
  const storeListApi = `${getLinkDomain()}/dt/api/storeList/storesByAddressOrCoordinates?brand=NM&freeFormAddress=${location}&mileRadius=100`;
  return axios.get(storeListApi);
};

export const getBopsList = (location, sku) => {
  const bopsListApi = `/dt/api/stores?brandCode=nm&freeFormAddress=${location}&skuId=${sku}&quantity=1`;
  return axios.get(bopsListApi);
};

export const setStoreCookie = ({ id }) => {
  cookie.save('dt_selected_store', id, { path: '/' });
};

export const getStoreCookie = () => {
  const dtSelectedStore = cookie.load('dt_selected_store');
  const dtFavoriteStore = cookie.load('dt_favorite_store');
  const cookiePrecedence = dtSelectedStore || dtFavoriteStore || undefined;
  return {
    number: cookiePrecedence && cookiePrecedence.split('/')[0],
    id: cookiePrecedence,
  };
};

export const validateEmail = (email) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/;
  return regexp.test(email);
};

export const getConfig = () => {
  const config = window && window.YourNeimansConfig;
  if (config) {
    const {
      YOUR_NEIMANS_DEFAULT_SA = false,
      YOUR_NEIMANS_SA = false,
      YOUR_NEIMANS_SA_INTERACTION = false,
      YOUR_NEIMANS_SA_SEND_BAG = false,
      YOUR_NEIMANS_SA_EMAIL = false,
      SALES_ASSOCIATES_V2 = false,
      LOGIN_PANEL = false,
      LOCATION = '',
      INCIRCLE_PANEL_PAGE = false,
      GIFTCARDS_PANEL = false,
      PANEL_OFFERS = false,
      PROMO_NOTIFICATIONS = false,
      PROMOGC_PANEL = false,
      GUEST_IDENTITY_DT = false,
      MY_STYLE_PREFERENCES = false,
      FINGERPRINT_PRO = false,
      IMPROVED_PANEL_CTA = false,
      GIFT_CARD_NOTIFICATIONS = false,
      INCIRCLE_NOTIFICATIONS = false,
      DT_LOGIN_FLOW = false,
      UCA_PROFILE_COOKIE = false,
      EMPLOYEE_STATUS_V2 = false,
      DISCOUNT_ELIGIBILITY_V1 = false,
      LOCK_AUTHSTATUS_API = false,
      PZP_IDENTITY = false,
      DISABLE_ATG_LOGIN = false,
      NEW_STORES_UI = false,
      UCA_MFA = false,
      PANEL_SCRIPT = false,
      ADOBE_PZP_IDENTITY = false,
      SHOW_INCIRCLE_YEAR = false,
      VALIDATE_LOGIN = false,
      P13N_API = false,
      USE_CONFIDENTIAL_CLIENT = false,
      USE_SRP_AUTH = false,
      USE_AUTH_CONFIG = false,
      FP_OPENSOURCE = false,
      NMO_LOYALTY_NEW = false,
      LOY_LANDING = false,

    } = config;

    return {
      toggles: {
        YOUR_NEIMANS_SA,
        YOUR_NEIMANS_DEFAULT_SA,
        YOUR_NEIMANS_SA_INTERACTION,
        YOUR_NEIMANS_SA_SEND_BAG,
        YOUR_NEIMANS_SA_EMAIL,
        SALES_ASSOCIATES_V2,
        LOGIN_PANEL,
        INCIRCLE_PANEL_PAGE,
        GIFTCARDS_PANEL,
        PANEL_OFFERS,
        PROMO_NOTIFICATIONS,
        PROMOGC_PANEL,
        GUEST_IDENTITY_DT,
        FINGERPRINT_PRO,
        IMPROVED_PANEL_CTA,
        MY_STYLE_PREFERENCES,
        GIFT_CARD_NOTIFICATIONS,
        INCIRCLE_NOTIFICATIONS,
        DT_LOGIN_FLOW,
        UCA_PROFILE_COOKIE,
        EMPLOYEE_STATUS_V2,
        DISCOUNT_ELIGIBILITY_V1,
        LOCK_AUTHSTATUS_API,
        PZP_IDENTITY,
        NEW_STORES_UI,
        DISABLE_ATG_LOGIN,
        UCA_MFA,
        PANEL_SCRIPT,
        ADOBE_PZP_IDENTITY,
        SHOW_INCIRCLE_YEAR,
        VALIDATE_LOGIN,
        P13N_API,
        USE_CONFIDENTIAL_CLIENT,
        USE_SRP_AUTH,
        USE_AUTH_CONFIG,
        FP_OPENSOURCE,
        NMO_LOYALTY_NEW,
        LOY_LANDING,
      },
      location: LOCATION,
    };
  }
  return {
    toggles: {},
    location: '',
  };
};

export const getMFAConfig = () => {
  const config = window && window.MFAConfig;
  if (config) {
    const {
      YOUR_NEIMANS_DEFAULT_SA = false,
      YOUR_NEIMANS_SA = false,
      YOUR_NEIMANS_SA_INTERACTION = false,
      YOUR_NEIMANS_SA_SEND_BAG = false,
      YOUR_NEIMANS_SA_EMAIL = false,
      SALES_ASSOCIATES_V2 = false,
      LOGIN_PANEL = false,
      LOCATION = '',
      INCIRCLE_PANEL_PAGE = false,
      GIFTCARDS_PANEL = false,
      PANEL_OFFERS = false,
      PROMO_NOTIFICATIONS = false,
      PROMOGC_PANEL = false,
      GUEST_IDENTITY_DT = false,
      MY_STYLE_PREFERENCES = false,
      FINGERPRINT_PRO = false,
      IMPROVED_PANEL_CTA = false,
      GIFT_CARD_NOTIFICATIONS = false,
      INCIRCLE_NOTIFICATIONS = false,
      DT_LOGIN_FLOW = false,
      UCA_PROFILE_COOKIE = false,
      EMPLOYEE_STATUS_V2 = false,
      DISCOUNT_ELIGIBILITY_V1 = false,
      LOCK_AUTHSTATUS_API = false,
      PZP_IDENTITY = false,
      DISABLE_ATG_LOGIN = false,
      NEW_STORES_UI = false,
      UCA_MFA = false,
      PANEL_SCRIPT = false,
      ADOBE_PZP_IDENTITY = false,
      SHOW_INCIRCLE_YEAR = false,
      VALIDATE_LOGIN = false,
      P13N_API = false,
      USE_CONFIDENTIAL_CLIENT = false,
      USE_SRP_AUTH = false,
      USE_AUTH_CONFIG = false,
      FP_OPENSOURCE = false,
      NMO_LOYALTY_NEW = false,
      LOY_LANDING = false,

    } = config;

    return {
      toggles: {
        YOUR_NEIMANS_SA,
        YOUR_NEIMANS_DEFAULT_SA,
        YOUR_NEIMANS_SA_INTERACTION,
        YOUR_NEIMANS_SA_SEND_BAG,
        YOUR_NEIMANS_SA_EMAIL,
        SALES_ASSOCIATES_V2,
        LOGIN_PANEL,
        INCIRCLE_PANEL_PAGE,
        GIFTCARDS_PANEL,
        PANEL_OFFERS,
        PROMO_NOTIFICATIONS,
        PROMOGC_PANEL,
        GUEST_IDENTITY_DT,
        FINGERPRINT_PRO,
        IMPROVED_PANEL_CTA,
        MY_STYLE_PREFERENCES,
        GIFT_CARD_NOTIFICATIONS,
        INCIRCLE_NOTIFICATIONS,
        DT_LOGIN_FLOW,
        UCA_PROFILE_COOKIE,
        LOCK_AUTHSTATUS_API,
        PZP_IDENTITY,
        NEW_STORES_UI,
        DISABLE_ATG_LOGIN,
        UCA_MFA,
        PANEL_SCRIPT,
        ADOBE_PZP_IDENTITY,
        SHOW_INCIRCLE_YEAR,
        VALIDATE_LOGIN,
        P13N_API,
        USE_CONFIDENTIAL_CLIENT,
        USE_SRP_AUTH,
        USE_AUTH_CONFIG,
        EMPLOYEE_STATUS_V2,
        DISCOUNT_ELIGIBILITY_V1,
        FP_OPENSOURCE,
        NMO_LOYALTY_NEW,
        LOY_LANDING,
      },
      location: LOCATION,
    };
  }
  return {
    toggles: {},
    location: '',
  };
};

export const getEmail = () => {
  const userData = filterLocalCognito('userData');
  if (userData) return filterUserAttributes(userData, 'email');
  return '';
};

export const getProfileData = () => {
  const ucaProfileDataCookie = cookie.load('ucaProfileData') || {};
  const {
    firstName,
    securityStatus,
    cartItemCount,
    universal_customer_id,
    countryPreference,
  } = ucaProfileDataCookie;
  return {
    name: firstName,
    bagCount: cartItemCount || 0,
    isAuthenticated: securityStatus === 'Authenticated',
    ucaid: universal_customer_id,
    securityStatus,
    countryPreference,
  };
};

export const getInitials = (name) => {
  return name.match(/\b(\w)/g).join('');
};

export const capitalize = (string) => {
  return string
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const sortStoreData = (storeData, initialStoreSelected) => {
  const sortedStoreData = [...storeData].sort(
    (a, b) => a.distance - b.distance
  );

  if (initialStoreSelected) {
    const isStoreInList = storeData.some(
      (store) => store.id === initialStoreSelected.id
    );

    isStoreInList
      ? sortedStoreData.some(
        (store, i) => store.id === initialStoreSelected.id
            && sortedStoreData.unshift(sortedStoreData.splice(i, 1)[0])
      )
      : sortedStoreData.unshift(initialStoreSelected);
  }

  return sortedStoreData;
};

export const getTimePrompt = (hours) => {
  const date = new Date();
  const clientDay = date.getDay() === 0 ? 6 : date.getDay() - 1;

  return `${hours[clientDay]?.label}.${hours[clientDay]?.hours[0]}`;
};

export const updateNameHeaders = (updateName, updateSiteHeader, user) => {
  const name = updateName() || user?.attributes?.given_name; //eslint-disable-line
  if (updateSiteHeader) {
    updateSiteHeader(name, user?.attributes?.email);
  } else {
    const headers = document.querySelectorAll(
      '#your-neimans-cta.your-neimans-cta'
    );

    headers.forEach((header) => {
      header.childNodes.forEach((node) => {
        if (node.data?.toLowerCase().indexOf('sign in') >= 0) {
          node.data = `Welcome, ${name}`;
        }
      });
    });
  }

  const ordersLink = document.getElementById('top-row-my-orders');
  if (name && ordersLink?.href?.endsWith('guest-order-history')) {
    ordersLink.href = ordersLink.href.replace('/guest-order-history', '/order-history');
  }
};

export const updateShoppingCart = () => {
  const { bagCount } = getProfileData();
  if (bagCount > 0) {
    if (!isMobile()) {
      const miniCartButtonDT = document.getElementById('miniCartButton');
      const miniCartButtonATG = document.getElementById('btn_ShoppingBag');
      if (miniCartButtonDT) {
        miniCartButtonDT.className = 'shopping-link checkout-link';
        miniCartButtonDT.ariaExpanded = 'false';
        miniCartButtonDT.textContent = `Checkout ${bagCount}`;
      }
      if (miniCartButtonATG) {
        miniCartButtonATG.className = 'button button-input-height button-action shopping-link checkout-link layer-trigger';
        miniCartButtonATG.ariaExpanded = 'false';
        miniCartButtonATG.ariaLabel = 'shopping bag';
        miniCartButtonATG.href = '#';
        miniCartButtonATG.setAttribute('role', 'button');
        miniCartButtonATG.setAttribute('data-layer-width', 300);
        miniCartButtonATG.setAttribute('data-modal-map-obj', 'miniCart');
        miniCartButtonATG.textContent = `Checkout ${bagCount}`;
      }
    } else {
      const miniCartButtonMobileDT = document.getElementById(
        'miniCartButtonMobile'
      );
      const miniCartButtonMobileATG = document.querySelectorAll(
        '.mobile-header a.mobile-icon-bag'
      )[0];
      if (miniCartButtonMobileATG) {
        miniCartButtonMobileATG.innerHTML = `
            <div class="responsive-sprites " data-asset="sprite-mobile-bag-fullwidth">
            <img src="/images/WN/sprite-home-fullwidth.png" alt="Mobile Shopping Bag Icon"></div>
            <span>${bagCount}</span>
        `;
      }
      if (miniCartButtonMobileDT) {
        const shoppingBagTitleText = bagCount === 0 || bagCount > 1
          ? `Shopping Bag containing ${bagCount} items`
          : 'Shopping Bag contains 1 item';
        miniCartButtonMobileDT.innerHTML = `
          <div class="items-in-shopping-bag-container">
            <span aria-hidden="true" class="number-of-items-mobile-shopping-bag">
             ${bagCount}
            </span>
           <img
            class="mobile-header-shopping-bag-icon-with-items"
            src="${String(itemsInShoppingBagSVG)}"
            alt="${shoppingBagTitleText}"
            />
         </div>`;
      }
    }
  }
};

export const signOutAtgHeader = () => {
  const headers = document.querySelectorAll(
    'div#your-neimans-cta.your-neimans-cta', 'button#your-neimans-cta.your-neimans-cta'
  );
  headers.forEach((header) => {
    header.childNodes.forEach((node) => {
      if (node.data?.toLowerCase().indexOf('welcome') >= 0) {
        node.data = 'Sign In';
      }
    });
  });
};

export function parsePanelOptCookie() {
  const optCookie = cookie.load('_optanalytics');
  if (optCookie) {
    let keyVal;
    const cookieArr = optCookie.split(',');

    for (let i = 0; i < cookieArr.length; i++) {
      keyVal = cookieArr[i].split(':');
      if (keyVal[0] === 'nmsw0002') {
        return keyVal[1];
      }
    }
  }
  return null;
}

export const shortenName = (name = '', maxLength) => {
  const adjustedLength = maxLength - 3;
  if (name.length > maxLength) {
    return `${name.substring(0, adjustedLength / 2)}...${name.substring(
      name.length - adjustedLength / 2
    )}`;
  }
  return name;
};

export const sortAccounts = (incircleData = []) => {
  return incircleData
    .map((account) => {
      const level = Math.max(
        +account.benefit_level || -1,
        +account.earned_level || -1
      );
      return { ...account, level };
    })
    .sort((acc1, acc2) => {
      const acc1Level = acc1.level;
      const acc2Level = acc2.level;
      if (acc1Level === acc2Level) return acc2.redeem_avail - acc1.redeem_avail;
      return acc2Level - acc1Level;
    });
};

export const formatCardData = (accountNumber, expireDate) => {
  if (!accountNumber || !expireDate) {
    return {
      formattedNumber: accountNumber,
      formattedDate: expireDate,
    };
  }

  const newDate = new Date(expireDate).toLocaleDateString('en-US', {
    timeZone: 'UTC',
  });

  const formattedDate = newDate
    .split('/')
    .map((val, i, arr) => (i !== arr.length - 1 ? `0${val}`.slice(-2) : val))
    .join('/');

  return {
    formattedNumber: `XXXX-XXXX-XXXX-${accountNumber.slice(-4)}`,
    formattedDate,
  };
};

export const fetchPromoCardData = (fastlyHost, profileId, headers) => {
  const promoCardURL = `${fastlyHost}/customer-data/v1/${profileId}`;
  const promoCardHeaders = { 'X-NMG-Card-Class': 'PROMO', ...headers };
  return axios.get(`${promoCardURL}/giv-cards`, { headers: promoCardHeaders });
};

export const hasExpiringOffer = (offerData) => {
  return offerData.some(({ endDate }) => {
    const twoDaysInMS = 86400000 * 2;
    return new Date(endDate) - Date.now() < twoDaysInMS;
  });
};

export const hasPromoCardExpiringOffer = (promoCardData) => {
  return promoCardData.some((item) => {
    const twoDaysInMS = 86400000 * 2;
    return new Date(item.expire_date) - Date.now() < twoDaysInMS;
  });
};

export const hasIncircleGiftCardExpiringOffer = (incircleCardData) => {
  return incircleCardData.some((item) => {
    const twoDaysInMS = 86400000 * 2;
    return new Date(item.expire_date) - Date.now() < twoDaysInMS;
  });
};

export const getOfferNotificationCount = (offerData = [], profileId) => {
  const lastPromoVisit = localStorage.getItem(`${profileId}.lastPromoVisit`);
  if (lastPromoVisit) {
    const promoVisitTs = parseInt(lastPromoVisit, 10);
    return offerData.reduce(
      (acc, { addedAt }) => (addedAt > promoVisitTs ? acc + 1 : acc),
      0
    );
  }
  return offerData.length;
};

export const getGiftCardCount = ({ data }, profileId) => {
  const cachedCardData = window?.localStorage.getItem(
    `${profileId}.lastViewedPromoCards`
  );
  const cachedPromoCards = JSON.parse(cachedCardData);
  if (cachedCardData && cachedCardData.length > 0) {
    return data.reduce(
      (acc, { ki }) => (cachedPromoCards.findIndex((card) => card.ki === ki) === -1
        ? acc + 1
        : acc),
      0
    );
  }
  return data.length;
};

export const getIncircleNotificationCount = (data, profileId) => {
  const cachedIncircleData = JSON.parse(
    window.localStorage.getItem(`incircleData.${profileId}`)
  );
  if (cachedIncircleData && cachedIncircleData.length > 0) {
    return data.reduce(
      (acc, { ki }) => (cachedIncircleData.findIndex((card) => card.ki === ki) === -1
        ? acc + 1
        : acc),
      0
    );
  }
  return data.length;
};
export const determineOfferTemplate = (
  offerNotificationCount,
  offers,
  hasExpiringOffer
) => {
  const subtextClass = 'panel__account--subtext';
  const hasNotifications = offerNotificationCount >= 1;
  const hasMultipleOffers = offers > 1;
  const hasSubtext = (offers >= 1 && !hasExpiringOffer)
    || (offerNotificationCount >= 1 && offers < 1);
  let offerText = 'Offers - None Available';

  if (hasNotifications) {
    offerText = 'Offers - New';
    if (!hasMultipleOffers) offerText += '!';
  } else if (offers > 0) {
    if (hasExpiringOffer) offerText = 'Offers - Expiring Soon!';
    else offerText = 'Offers - ';
  }

  return (
    <div className="panel__account--notificationContainer">
      {hasNotifications && <Notification num={offerNotificationCount} />}
      {offerText}
      {hasSubtext && (
      <span className={subtextClass}>
(
        {offers}
        {' '}
total)
      </span>
      )}
    </div>
  );
};

export const determinePromocardTemplate = (
  promoCardNotificationCount,
  promoCards,
  hasPromoCardExpiringOffer
) => {
  const subtextClass = 'panel__account--subtext';
  const hasPromoCardNotifications = promoCardNotificationCount >= 1;
  const hasMultipleOffers = promoCards > 1;
  const hasSubtext = (promoCards >= 1 && !hasPromoCardExpiringOffer)
    || (promoCardNotificationCount >= 1 && promoCards < 1);
  let promoCardText = 'Promotional Gift Cards - None Available';
  if (hasPromoCardNotifications) {
    promoCardText = 'Promotional Gift Cards - New';
    if (!hasMultipleOffers) promoCardText += '!';
  } else if (promoCards > 0) {
    if (hasPromoCardExpiringOffer) promoCardText = 'Promotional Gift Cards - Expiring Soon!';
    else promoCardText = 'Promotional Gift Cards - ';
  }
  return (
    <div className="panel__account--notificationContainer">
      {hasPromoCardNotifications && (
        <Notification num={promoCardNotificationCount} />
      )}
      {promoCardText}
      {hasSubtext && (
        <span className={subtextClass}>
(
          {`${promoCards}`}
          {' '}
total)
        </span>
      )}
    </div>
  );
};

export const determineIncircleCardTemplate = (
  incircleCardNotificationCount,
  inCircleCards,
  hasIncircleCardExpiringOffer
) => {
  const subtextClass = 'panel__account--subtext';
  const hasIncircleCardNotifications = incircleCardNotificationCount >= 1;
  const hasMultipleOffers = inCircleCards > 1;
  const hasSubtext = (inCircleCards >= 1 && !hasIncircleCardExpiringOffer)
    || (incircleCardNotificationCount >= 1 && inCircleCards < 1);
  let inCircleCardText = '';
  if (hasIncircleCardNotifications) {
    inCircleCardText = 'Incircle Rewards - New';
    if (!hasMultipleOffers) inCircleCardText += '!';
  } else if (inCircleCards > 0) {
    if (hasIncircleCardExpiringOffer) inCircleCardText = 'Incircle Rewards - Expiring Soon!';
  }
  if (inCircleCardText === '') {
    return null;
  }

  return (
    <div className="panel__account--notificationContainer">
      {hasIncircleCardNotifications && (
        <Notification num={incircleCardNotificationCount} />
      )}
      {inCircleCardText}
      {hasSubtext && (
        <span className={subtextClass}>
(
          {`${inCircleCards}`}
          {' '}
total)
        </span>
      )}
    </div>
  );
};

export const setUcaProfileCookie = (
  user,
  successCb,
  EMPLOYEE_STATUS_V2,
  DISCOUNT_ELIGIBILITY_V1,
) => {
  const lambdaHost = window.sessionStorage.getItem('lambdaHost');
  const fastlyHost = window.sessionStorage.getItem('fastlyUCAHost') || lambdaHost;
  const token = user?.signInUserSession?.idToken?.jwtToken;
  const webProfileId = user?.attributes?.preferred_username; //eslint-disable-line
  const ucaProfileId = user?.attributes?.sub;

  const ucaProfileData = cookie.load('ucaProfileData');
  const cartItemCount = axios
    .get(`${fastlyHost}/oco-cart-items/v3/${webProfileId}/cart/count`, {
      headers: {
        sourceApp: 'WN',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    });
  let employeeStatusUrl = `${fastlyHost}/employees/v1/profiles/${ucaProfileId}/employee-status`;
  if (EMPLOYEE_STATUS_V2 && DISCOUNT_ELIGIBILITY_V1) {
    employeeStatusUrl = `${fastlyHost}/employees/v1/profiles/${ucaProfileId}/discount-eligibility`;
  } else if (EMPLOYEE_STATUS_V2 && !DISCOUNT_ELIGIBILITY_V1) {
    employeeStatusUrl = `${fastlyHost}/employees/v2/profiles/${ucaProfileId}/employee-status`;
  }

  const customerType = axios
    .get(
      employeeStatusUrl,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res?.data?.isEmployee;
    });

  Promise.allSettled([cartItemCount, customerType]).then((result) => {
    const profileType = result[1]?.value ? 'employee' : 'customer';
    const expiresAt = Date.now() + 2592000000; // 30 days
    const expirationDate = new Date(expiresAt);
    cookie.save(
      'ucaProfileData',
      {
        ...ucaProfileData,
        firstName: user?.attributes?.given_name, //eslint-disable-line
        securityStatus: 'Authenticated',
        cartItemCount: result[0]?.value || 0,
        universal_customer_id: user?.attributes?.sub,
        logged_in_status: true,
        customer_registered: true,
        profile_type: profileType,
        customer_segment:
          window?.sessionStorage?.getItem('loyaltyData')?.segment || '0',
        countryPreference: ucaProfileData?.countrypreference || 'US',
        currencyPreference: ucaProfileData?.currencyPreference || 'USD',
        localeUrl: ucaProfileData?.localeUrl || '/en-us',
      },
      {
        path: '/',
        expires: expirationDate,
        secure: true,
      }
    );
    return successCb(user);
  });
};
export const logoutUcaProfileCookie = () => {
  const ucaProfileData = cookie.load('ucaProfileData');
  const expiresAt = Date.now() + 2592000000; // 30 days
  const expirationDate = new Date(expiresAt);
  cookie.save(
    'ucaProfileData',
    {
      ...ucaProfileData,
      firstName: '',
      securityStatus: 'Anonymous',
      cartItemCount: 0,
      universal_customer_id: '',
      logged_in_status: false,
      customer_registered: false,
      profile_type: 'customer',
      customer_segment: '0',
      countryPreference: ucaProfileData?.countrypreference || 'US',
      currencyPreference: ucaProfileData?.currencyPreference || 'USD',
      localeUrl: ucaProfileData?.localeUrl || '/en-us',
    },
    { path: '/', expires: expirationDate, secure: true }
  );
};
export const removeCognitoCookies = () => {
  const listCookies = () => {
    const cookies = document?.cookie?.split(';');

    return cookies.reduce((acc, docCookie) => {
      const [cookieName, cookieValue] = docCookie?.trim().split('=');

      return { ...acc, [cookieName]: cookieValue };
    }, {});
  };

  const cookiesKeys = Object.keys(listCookies()).filter((key) => key.startsWith('CognitoIdentityServiceProvider.'));

  cookiesKeys.forEach((key) => {
    cookie.remove(key, { path: '/' });
  });
};
export const knownCustomerCookie = (user) => {
  const knownCustomer = cookie.load('cstmr');
  const expiresAt = Date.now() + 2592000000; // 30 days
  const expirationDate = new Date(expiresAt);
  cookie.save(
    'cstmr',
    {
      customerId: user?.attributes?.sub,
      cmdId: knownCustomer?.cmdId || '',
      isLoggedin: true,
      isGuest: false,
    },
    { path: '/', expires: expirationDate, secure: true }
  );
};

export const logOutknownCustomerCookie = () => {
  const knownCustomer = cookie.load('cstmr');
  const expiresAt = Date.now() + 2592000000; // 30 days
  const expirationDate = new Date(expiresAt);
  if (knownCustomer?.customerId) {
    cookie.save(
      'cstmr',
      { ...knownCustomer, isLoggedin: false, isGuest: false },
      { path: '/', expires: expirationDate, secure: true }
    );
  }
};

export const getHashedMailAddress = (emailId = '') => {
  let hashedMailId = '';
  if (typeof emailId === 'string') {
    const email = emailId?.toLowerCase() || '';
    for (let i = 0; i < email.length; i++) {
      hashedMailId += hashCodes[email[i]];
    }
  }
  return hashedMailId;
};

const extractStoreNumber = (storeId) => (storeId && storeId.indexOf('/') === -1 ? storeId : storeId.slice(0, storeId.indexOf('/')));

const convertLowerCase = (name) => {
  const storeName = name.toLowerCase().split(' ');
  const displayName = [];

  storeName.forEach((e) => displayName.push(e.charAt(0).toUpperCase() + e.slice(1)));

  return displayName.join(' ');
};

export const getAvailableStoresInfo = () => {
  const selectedStore = cookie.load('dt_selected_store');
  const favoriteStore = cookie.load('dt_favorite_store');
  const cleanZip = cookie.load('dt_selected_zipcode')
    && cookie.load('dt_selected_zipcode').slice(1, -1);

  return { selectedStore, favoriteStore, cleanZip };
};

export const getMappedStoreInfo = async (selectedStore, favoriteStore, selectedZip) => {
  let storeInfo = {};
  const getStoreData = async (key, store) => {
    try {
      const storeData = await getStoreJson(extractStoreNumber(store));
      const displayText = convertLowerCase(storeData?.data?.name);

      storeInfo = { ...storeInfo, [key]: displayText, selectedZip };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error occurred while getting info about stores. Error: ${e}`);
    }
  };

  if (selectedStore && selectedStore !== 'undefined') await getStoreData('selectedStore', selectedStore);
  if (favoriteStore && favoriteStore !== 'undefined') await getStoreData('favoriteStore', favoriteStore);
  if (selectedZip) storeInfo = { ...storeInfo, selectedZip };
  return storeInfo;
};

export const filterActiveStores = (storesData) => {
  if (storesData?.length > 0) {
    return storesData.filter((str) => get(str, 'storeServices.inStoreShopping.isOpen', false))
      .sort((s1, s2) => s1.distance - s2.distance);
  }
  return storesData || [];
};
